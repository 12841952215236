import { createTheme } from '@mui/material';

const palette = {
  primary: {
    light: '#000000',
    dark: 'rgb(18, 19, 28)',
    main: '#ffffff',
    contrastText: '#000000'
  },
  background: {
    // background colors
    default: 'rgba(33, 36, 51, 1)',
    secondary: 'rgba(33, 36, 51, 0.1)',
    transparent: 'rgb(18, 19, 28)',
    disabled: '#808080',

    // hover background colors
    hover: {
      dark: '#4f567d',
      light: '#1976d2',
    },

    // active background color
    active: '#d62246BF',
  },
  text: {
    primary: 'rgb(240, 240, 240)',
    secondary: 'rgb(143, 146, 161)',
    disabled: '#808080',
  },
  border: {
    // border colors
    main: 'rgb(240, 240, 240)',
    disabled: '#808080',

    // border colors on hover
    hover: {
      light: '#1976d2',
    },
  },

  paragraph: "16px"

};

export const theme = createTheme({
  palette: { ...palette },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'rgb(240, 240, 240)',
        fontSize: "15px"
      },
      // set the styles for different typography variants
      variants: [
        {
          props: {
            variant: 'h4',
          },
          style: {},
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: palette.background.transparent,
          color: palette.text.primary,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&:hover': {
            backgroundColor: `${palette.background.hover.dark} !important`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${palette.background.default} !important`,
          },
          '&:hover': {
            backgroundColor: `${palette.background.hover.dark} !important`,
          },
        },
      },
    },
    MuiChip: {
      color: palette.text.primary,
      background: 'gray',
    },
    MuiListItemText: {
      primary: {
        backgroundColor: 'rgb(18, 19, 28)',
      },
      secondary: {
        backgroundColor: 'rgb(18, 19, 28)',
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: palette.text.primary,
            borderColor: palette.text.primary,
            background: `${palette.background.default} !important`,
            '&:hover': {
              background: `${palette.background.active} !important`,
              borderColor: `${palette.background.active} !important`,
              color: palette.text.primary
            },
            '&.MuiButton-root.Mui-disabled': {
              color: palette.text.disabled,
              borderColor: palette.border.disabled,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            background: palette.background.default,
            '&:hover': {
              background: palette.background.active,
            },
          },
        },
      ],
    },
  },
});



// const palette = {
//   background: {
//     // background colors
//     default: 'rgb(0,0,0)',
//     secondary: 'rgba(255, 255, 255, 0.1)',
//     transparent: 'rgba(0, 0, 0, 0.8)',
//     disabled: '#808080',

//     // hover background colors
//     hover: {
//       dark: '#303030',
//       light: '#1976d2',
//     },

//     // active background color
//     active: '#E91F63',
//   },
//   text: {
//     primary: 'rgb(255,255,255)',
//     secondary: 'rgb(155,155,155)',
//     disabled: '#808080',
//   },
//   border: {
//     // border colors
//     main: 'rgb(255,255,255)',
//     disabled: '#808080',

//     // border colors on hover
//     hover: {
//       light: '#1976d2',
//     },
//   },        paragraph: "16px"

// };

// export const theme = createTheme({
//   palette: { ...palette },
//   components: {
//     MuiTypography: {
//       defaultProps: {
//         color: 'white',
//         fontSize: "15px"
//       },
//       // set the styles for different typography variants
//       variants: [
//         {
//           props: {
//             variant: 'h4',
//           },
//           style: {},
//         },
//       ],
//     },
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           background: palette.background.transparent,
//           color: palette.text.primary,
//         },
//       },
//     },
//     MuiAutocomplete: {
//       styleOverrides: {
//         option: {
//           '&:hover': {
//             backgroundColor: `${palette.background.hover.dark} !important`,
//           },
//         },
//       },
//     },
//     MuiMenuItem: {
//       styleOverrides: {
//         root: {
//           '&.Mui-selected': {
//             backgroundColor: `${palette.background.default} !important`,
//           },
//           '&:hover': {
//             backgroundColor: `${palette.background.hover.dark} !important`,
//           },
//         },
//       },
//     },
//     MuiChip: {
//       color: palette.text.primary,
//       background: 'gray',
//     },
//     MuiButton: {
//       variants: [
//         {
//           props: { variant: 'outlined' },
//           style: {
//             color: palette.text.primary,
//             borderColor: palette.border.main,
//             background: `${palette.background.default} !important`,
//             '&:hover': {
//               background: `${palette.background.default} !important`,
//               borderColor: palette.border.hover,
//             },
//             '&.MuiButton-root.Mui-disabled': {
//               color: palette.text.disabled,
//               borderColor: palette.border.disabled,
//             },
//           },
//         },
//         {
//           props: { variant: 'contained' },
//           style: {
//             background: palette.background.secondary,
//             '&:hover': {
//               background: palette.background.hover.light,
//             },
//           },
//         },
//       ],
//     },
//   },
// });
