//@flow
import { apiClient, fileClient } from 'store';
import type { CrudAction } from './typeDefs';

export const apiCaller = (url, action: CrudAction) => {
  if (action.fakeCall) return {};
  const { method, path, params, data, fetchConfig } = action.payload;
  return apiClient[method](path, { params, data, fetchConfig });
};
export const fileCaller = async (url, action: CrudAction) => {
  if (action.fakeCall) return {};
  console.log(action.payload)
  const { method, path, params, data, fetchConfig } = action.payload;
  return fileClient[method](path, { params, data, fetchConfig });
};