import React from 'react';
import Routes from './Routes';
import AppToastContainer from '../AppToastContainer';
import { ThemeProvider } from '@mui/material';
import { theme } from './Theme';

const Root = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes />
        <AppToastContainer />
      </ThemeProvider>
    </>
  );
};

export default Root;
